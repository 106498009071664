/* work-sans-100 - vietnamese_latin-ext_latin */
@font-face {
    font-family: 'WORK_SANS';
    font-style: normal;
    font-weight: 100;
    src: url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-100.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-100.woff2') format('woff2'), /* Super Modern Browsers */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-100.woff') format('woff'), /* Modern Browsers */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-100.ttf') format('truetype'), /* Safari, Android, iOS */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-100.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-regular - vietnamese_latin-ext_latin */
@font-face {
    font-family: 'WORK_SANS';
    font-style: normal;
    font-weight: 400;
    src: url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-regular.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-700 - vietnamese_latin-ext_latin */
@font-face {
    font-family: 'WORK_SANS';
    font-style: normal;
    font-weight: 700;
    src: url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-700.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-100italic - vietnamese_latin-ext_latin */
@font-face {
    font-family: 'WORK_SANS';
    font-style: italic;
    font-weight: 100;
    src: url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-100italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-100italic.woff') format('woff'), /* Modern Browsers */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-100italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-italic - vietnamese_latin-ext_latin */
@font-face {
    font-family: 'WORK_SANS';
    font-style: italic;
    font-weight: 400;
    src: url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-700italic - vietnamese_latin-ext_latin */
@font-face {
    font-family: 'WORK_SANS';
    font-style: italic;
    font-weight: 700;
    src: url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('./WorkSans/work-sans-v9-vietnamese_latin-ext_latin-700italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}