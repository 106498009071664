@import "deziro-web-app-components/assets/fonts/DMSans.css";
@import "deziro-web-app-components/assets/fonts/Roboto.css";
@import "deziro-web-app-components/assets/fonts/OpenSans.css";
@import "deziro-web-app-components/assets/fonts/WorkSans.css";
@import "deziro-web-app-components/assets/fonts/IBMPlex.css";
@import "deziro-web-app-components/assets/fonts/Playfair.css";
@import "deziro-web-app-components/assets/fonts/Oswald.css";
@import "deziro-web-app-components/assets/fonts/Lora.css";
@import "deziro-web-app-components/assets/fonts/Merriweather.css";
@import "deziro-web-app-components/assets/fonts/Montserrat.css";
@import "deziro-web-app-components/assets/fonts/SourceSerifPro.css";

body {
  margin: 0;
  font-family: 'DMSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

// Remove input number arrows
input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Increase input password letter spacing
input[type=password] {
  letter-spacing: 4px; // theme.spacing(0.5)
}