/* playfair-display-regular - vietnamese_latin-ext_latin_cyrillic */
@font-face {
    font-family: 'PLAYFAIR';
    font-style: normal;
    font-weight: 400;
    src: url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */ url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-regular.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}

/* playfair-display-700 - vietnamese_latin-ext_latin_cyrillic */
@font-face {
    font-family: 'PLAYFAIR';
    font-style: normal;
    font-weight: 700;
    src: url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */ url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */ url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-700.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}

/* playfair-display-italic - vietnamese_latin-ext_latin_cyrillic */
@font-face {
    font-family: 'PLAYFAIR';
    font-style: italic;
    font-weight: 400;
    src: url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */ url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-italic.woff') format('woff'), /* Modern Browsers */ url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-italic.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}

/* playfair-display-700italic - vietnamese_latin-ext_latin_cyrillic */
@font-face {
    font-family: 'PLAYFAIR';
    font-style: italic;
    font-weight: 700;
    src: url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */ url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */ url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Playfair/playfair-display-v22-vietnamese_latin-ext_latin_cyrillic-700italic.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}