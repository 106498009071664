$scrollbarBg: transparent;

/* total width */
*::-webkit-scrollbar {
  background-color: #fff;
  width: 10px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: $scrollbarBg;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid $scrollbarBg;
}
