/* merriweather-regular - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'MERRYWEATHER';
    font-style: normal;
    font-weight: 400;
    src: url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */ url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/* merriweather-italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'MERRYWEATHER';
    font-style: italic;
    font-weight: 400;
    src: url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */ url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-italic.woff') format('woff'), /* Modern Browsers */ url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-italic.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/* merriweather-700 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'MERRYWEATHER';
    font-style: normal;
    font-weight: 700;
    src: url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */ url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */ url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/* merriweather-700italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'MERRYWEATHER';
    font-style: italic;
    font-weight: 700;
    src: url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */ url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */ url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Merriweather/merriweather-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700italic.svg#Merriweather') format('svg'); /* Legacy iOS */
}