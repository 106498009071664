/* ibm-plex-mono-100 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'IBM_PLEX';
    font-style: normal;
    font-weight: 100;
    src: url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.woff2') format('woff2'), /* Super Modern Browsers */ url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.woff') format('woff'), /* Modern Browsers */ url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.ttf') format('truetype'), /* Safari, Android, iOS */ url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.svg#IBMPlexMono') format('svg'); /* Legacy iOS */
}

/* ibm-plex-mono-100italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'IBM_PLEX';
    font-style: italic;
    font-weight: 100;
    src: url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100italic.woff2') format('woff2'), /* Super Modern Browsers */ url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100italic.woff') format('woff'), /* Modern Browsers */ url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100italic.svg#IBMPlexMono') format('svg'); /* Legacy iOS */
}

/* ibm-plex-mono-regular - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'IBM_PLEX';
    font-style: normal;
    font-weight: 400;
    src: url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */ url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#IBMPlexMono') format('svg'); /* Legacy iOS */
}

/* ibm-plex-mono-700 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'IBM_PLEX';
    font-style: normal;
    font-weight: 700;
    src: url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */ url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */ url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.svg#IBMPlexMono') format('svg'); /* Legacy iOS */
}

/* ibm-plex-mono-700italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'IBM_PLEX';
    font-style: italic;
    font-weight: 700;
    src: url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */ url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */ url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('./IBMPlex/ibm-plex-mono-v6-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700italic.svg#IBMPlexMono') format('svg'); /* Legacy iOS */
}