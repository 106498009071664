/* oswald-regular - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'OSWALD';
    font-style: normal;
    font-weight: 400;
    src: url('./Oswald/oswald-v36-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./Oswald/oswald-v36-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Oswald/oswald-v36-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('./Oswald/oswald-v36-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */ url('./Oswald/oswald-v36-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Oswald/oswald-v36-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Oswald') format('svg'); /* Legacy iOS */
}

/* oswald-700 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'OSWALD';
    font-style: normal;
    font-weight: 700;
    src: url('./Oswald/oswald-v36-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./Oswald/oswald-v36-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Oswald/oswald-v36-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */ url('./Oswald/oswald-v36-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */ url('./Oswald/oswald-v36-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Oswald/oswald-v36-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.svg#Oswald') format('svg'); /* Legacy iOS */
}